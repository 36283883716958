import React from "react";
import useContracts from "./useContracts";

const useLoanApprovals = () => {
  const { loanRegistry } = useContracts();

  const [applications, setApplications] = React.useState<number>(0);
  const [approved, setApproved] = React.useState<number>(0);
  const [funded, setFunded] = React.useState<number>(0);

  React.useEffect(() => {
    if (!loanRegistry) { return; }

    loanRegistry.methods.getApplicationsCount().call().then((result: any) => {
      setApplications(result);
    });

    loanRegistry.methods.getApprovedCount().call().then((result: any) => {
      setApproved(result);
    });

    loanRegistry.methods.getFundedLoansCount().call().then((result: any) => {
      setFunded(result);
    });
  }, [loanRegistry]);

  return {
    applications,
    approved,
    funded,
  };
};

export default useLoanApprovals;
