import React from "react";
import useContracts from "./useContracts";

const useLoanApprovals = () => {
  const { loanRegistry } = useContracts();

  const [loanApprovalAddresses, setLoanApprovalAddresses] = React.useState<string[]>([]);
  const [page, setPage] = React.useState<number>(0);

  React.useEffect(() => {
    if (!loanRegistry) { return; }

    loanRegistry.methods.getApprovedLoans(0).call().then((result: any) => {
      setLoanApprovalAddresses(result);
    });
  }, [loanRegistry, page]);

  return [loanApprovalAddresses, setPage];
};

export default useLoanApprovals;
