import * as React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddressLink from "./AddressLink";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import { useHistory } from "react-router-dom";

interface IContractDetails {
  name: string;
  address: string;
  link?: string;
}

interface IProps {
  contracts: IContractDetails[];
}

const useStyles = makeStyles({
  table: {
    minWidth: 250,
  },
});

const AddressTable: React.FC<IProps> = (props) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Contract</TableCell>
            <TableCell align="right">Address</TableCell>
            <TableCell align="right">View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.contracts.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>

              <TableCell align="right">
                <AddressLink addr={row.address} />
              </TableCell>

              {row.link ? (
                <TableCell align="right">
                  <IconButton aria-label={`view ${row.name} in-app`} onClick={() => history.push(row.link as string)}>
                    <Visibility />
                  </IconButton>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AddressTable;
