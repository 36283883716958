import { CircularProgress, Grid } from "@material-ui/core";
import * as React from "react";
import AddressView from "../components/AddressView";
import { useBlockNumber } from "../helpers";
import useEthRPCStore from "../stores/useEthRPCStore";
import { hexToNumber } from "@etclabscore/eserialize";
import { History } from "history";
import { useContractKit } from "@celo-tools/use-contractkit";
import useContracts from "../hooks/useContracts";
import Stake from "../components/Stake";
import Unstake from "../components/Unstake";
import {utils} from "ethers";

import BigNumber from "bignumber.js";
import Withdraw from "../components/Withdraw";
import Delegation from "../components/Delegation";
import useConnectedAddress from "../stores/useConnectedAddress";
import DelegationList from "../components/Staking/DelegationsList";

const unit = require("ethjs-unit"); //tslint:disable-line

interface IProps {
  match: {
    params: {
      address: string,
      block: string,
    };
  };
  history: History;
}

const Address: React.FC<IProps> = ({ match, history }) => {
  const addr = match.params.address;
  const block = match.params.block;

  const [erpc] = useEthRPCStore();
  const [blockNumber] = useBlockNumber(erpc);

  const {corn, stkCorn, cUsd} = useContracts();
  const {delegations} = useConnectedAddress();

  const [transactionCount, setTransactionCount] = React.useState<string>();

  const [nativeBalance, setNativeBalance] = React.useState<string>();
  const [usdBalance, setUsdBalance] = React.useState<string>();
  const [cornBalance, setCornBalance] = React.useState<string>();
  const [balance, setBalance] = React.useState<string>();
  const [stakedBalance, setStakedBalance] = React.useState<string>();
  const [stakingRewards, setStakingRewards] = React.useState<string>();
  const [isStaking, setIsStaking] = React.useState<boolean>(false);
  const [isStartedUnstaking, setIsStartedUnstaking] = React.useState<boolean>(false);
  const [unusedCornVoteBalance, setUnusedCornVoteBalance] = React.useState<string>("0");
  const [usedCornVoteBalance, setUsedCornVoteBalance] = React.useState<string>("0");

  const blockNum = block === undefined ? blockNumber : parseInt(block, 10);

  const { getConnectedKit, address } = useContractKit();

  React.useEffect(() => {
    if (isNaN(blockNum) || isNaN(blockNumber)) {
      return;
    }
    if (blockNum > blockNumber) {
      history.push(`/address/${addr}/${blockNumber}`);
    }
    if (blockNum < 0) {
      history.push(`/address/${addr}/0`);
    }
  }, [blockNumber, blockNum, history, addr]);

  React.useEffect(() => {
    if (blockNumber === undefined || !erpc) {
      return;
    }
    const hexBlockNumber = `0x${blockNumber.toString(16)}`;
    erpc.eth_getTransactionCount(addr, hexBlockNumber).then((txCount) => {
      if (txCount === null) { return; }
      setTransactionCount(txCount);
      return txCount;
    });

    erpc.eth_getBalance(addr, hexBlockNumber).then((b) => {
      if (b === null) { return; }
      setNativeBalance(b);
    });

    if (cUsd) {
      cUsd.balanceOf(addr).then((bal: any) => {
        setUsdBalance(bal);
      });
    }

    if (corn) {
      corn.methods.balanceOf(addr).call().then((bal: any) => {
        setCornBalance(bal);
      });
    }

    if (stkCorn) {
      stkCorn.methods.stakedBalanceOf(addr).call().then((bal: any) => {
        setStakedBalance(bal);
      });

      stkCorn.methods.balanceOf(addr).call().then((bal: any) => {
        setBalance(bal);
      });

      stkCorn.methods.unpaidBalanceOf(addr).call().then((bal: any) => {
        setStakingRewards(bal);
      });

      stkCorn.methods.isStaking(addr).call().then((isStaked: boolean) => {
        setIsStaking(isStaked);
      });

      stkCorn.methods.isStartedUnstaking(addr).call().then((isUnstaking: boolean) => {
        setIsStartedUnstaking(isUnstaking);
      });

      stkCorn.methods.unusedCornVoteBalanceOf(addr).call().then((bal: any) => {
        setUnusedCornVoteBalance(bal);
      });

      stkCorn.methods.usedCornVoteBalanceOf(addr).call().then((bal: any) => {
        setUsedCornVoteBalance(bal);
      });

    }
  }, [blockNumber, addr, erpc, cUsd, corn, stkCorn]);

  if (transactionCount === undefined || nativeBalance === undefined) {
    return <CircularProgress />;
  }

  const handleStake = async (amount: string) => {
    const kkit = await getConnectedKit();
    const amnt = utils.parseUnits(amount, 18);
    const approvalTxRaw = await corn.methods.approve(stkCorn._address, amnt);
    const approvalTx = await kkit.sendTransactionObject(approvalTxRaw, { gas: "20000000", gasPrice: "1000000000" });
    const approvalR = await approvalTx.waitReceipt();
    if (approvalR.status !== true) {
      console.error("Approval failed");
      console.error(approvalR);
    }
    const stakeTxRaw = await stkCorn.methods.stake(amnt);
    const stakeTx = await kkit.sendTransactionObject(stakeTxRaw, { gas: "20000000", gasPrice: "1000000000" });
    const stakeR = await stakeTx.waitReceipt();
    if (stakeR.status !== true) {
      console.error("stake failed");
      console.error(stakeR);
    }
  };

  const handleUnstake = async () => {
    const kkit = await getConnectedKit();
    const unstakeTxRaw = await stkCorn.methods.unstake();
    const unstakeTx = await kkit.sendTransactionObject(unstakeTxRaw, { gas: "20000000", gasPrice: "1000000000" });
    const unstakeR = await unstakeTx.waitReceipt();
    if (unstakeR.status !== true) {
      console.error("unstake failed");
      console.error(unstakeR);
    }
  };

  const handleWithdraw = async () => {
    const kkit = await getConnectedKit();
    const withdrawTxRaw = await stkCorn.methods.withdraw();
    const withdrawTx = await kkit.sendTransactionObject(withdrawTxRaw, { gas: "20000000", gasPrice: "1000000000" });
    const withdrawR = await withdrawTx.waitReceipt();
    if (withdrawR.status !== true) {
      console.error("unstake failed");
      console.error(withdrawR);
    }
  };

  const handleDelegation = async (delegate: string, ratio: string) => {
    const kkit = await getConnectedKit();
    const setDelegateTxRaw = await stkCorn.methods.setDelegate(delegate, ratio);
    const setDelegateTx = await kkit.sendTransactionObject(setDelegateTxRaw, {
      gas: "20000000",
      gasPrice: "1000000000"
    });
    const setDelegateR = await setDelegateTx.waitReceipt();
    if (setDelegateR.status !== true) {
      console.error("unstake failed");
      console.error(setDelegateR);
    }
  };

  const handleUpdateDelegation = async (delegate: string, ratio: string) => {
    const kkit = await getConnectedKit();
    const setDelegateTxRaw = await stkCorn.methods.updateDelegation(delegate, ratio);
    const setDelegateTx = await kkit.sendTransactionObject(setDelegateTxRaw, {
      gas: "20000000",
      gasPrice: "1000000000"
    });
    const setDelegateR = await setDelegateTx.waitReceipt();
    if (setDelegateR.status !== true) {
      console.error("unstake failed");
      console.error(setDelegateR);
    }
  };

  const handleRemoveDelegation = async (delegate: string) => {
    const kkit = await getConnectedKit();
    const setDelegateTxRaw = await stkCorn.methods.unsetDelegate(delegate);
    const setDelegateTx = await kkit.sendTransactionObject(setDelegateTxRaw, {
      gas: "20000000",
      gasPrice: "1000000000"
    });
    const setDelegateR = await setDelegateTx.waitReceipt();
    if (setDelegateR.status !== true) {
      console.error("unstake failed");
      console.error(setDelegateR);
    }
  };

  const canDelegate = () => {
    return !!address;
  };

  const canStake = () => {
    return !!address;
  };

  const hasStakedBalance = () => {
    if (!address) { return false; }
    return new BigNumber(stakedBalance as string).isGreaterThan(0);
  };

  const canUnstake = () => {
    if (!address) { return false; }
    return hasStakedBalance() && canWithdraw() === false;
  };

  const canWithdraw = () => {
    if (!address) { return false; }
    return hasStakedBalance() && isStaking === false && isStartedUnstaking === false;
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Grid container justify="space-around" spacing={3}>
          <Grid item xs={3}>
            <AddressView
              address={addr}
              isStaking={isStaking}
              isStartedUnstaking={isStartedUnstaking}
              txCount={transactionCount ? hexToNumber(transactionCount) : 0}
              nativeBalance={unit.fromWei(nativeBalance || 0, "ether")}
              usdBalance={unit.fromWei(usdBalance || 0, "ether")}
              cornBalance={unit.fromWei(cornBalance || 0, "ether")}
              stakedBalance={unit.fromWei(stakedBalance || 0, "ether")}
              stakingRewards={unit.fromWei(stakingRewards || 0, "ether")}
              usedCornVoteBalance={unit.fromWei(usedCornVoteBalance || 0, "ether")}
              unusedCornVoteBalance={unit.fromWei(unusedCornVoteBalance || 0, "ether")}
            />
          </Grid>
          <Grid item xs={9}>
            <DelegationList
              canEdit={address === addr}
              delegations={delegations}
              onUpdate={handleUpdateDelegation}
              onRemove={handleRemoveDelegation}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify="space-around" spacing={3}>
          {canDelegate() ? <Grid item xs={4}>
            <Delegation
              alreadyDelegatedRatio={unit.fromWei(stakedBalance, "ether")}
              onSubmit={handleDelegation} />
          </Grid> : null }
          {canStake() ? <Grid item xs={2}>
            <Stake
              alreadyStaked={unit.fromWei(stakedBalance, "ether")}
              balance={unit.fromWei(cornBalance, "ether")}
              onSubmit={handleStake} />
          </Grid> : null }
          {canUnstake() ? <Grid item xs={2}>
            <Unstake
              balance={unit.fromWei(balance, "ether")}
              isStartedUnstaking={isStartedUnstaking}
              unpaid={unit.fromWei(stakingRewards, "ether")}
              onSubmit={handleUnstake}
            />
          </Grid> : null}
          {canWithdraw() ? <Grid item xs={2}>
            <Withdraw
              balance={unit.fromWei(balance, "ether")}
              unpaid={unit.fromWei(stakingRewards, "ether")}
              onSubmit={handleWithdraw}
            />
          </Grid> : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Address;
