
import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Card, CardActions, CardContent, TextField, InputAdornment } from "@material-ui/core";

interface IProps {
  interestOwed: string;
  usdBalance: string;
  onSubmit: (amount: string) => void;
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const MakePayment: React.FC<IProps> = (props) => {
  const classes = useStyles();

  const [paymentAmount, setPaymentAmount] = React.useState<string>("0");

  const changed = (ev: any) => {
    setPaymentAmount(ev.target.value);
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
         Make a Payment
        </Typography>
        <Typography variant="h5" component="h2">
          You currently owe {props.interestOwed}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          ({props.usdBalance} available)
        </Typography>
        <Typography variant="body2" component="p">
          Enter an amount to submit as payment
          <br />

          <TextField
            label="Amount"
            type="number"
            variant="filled"
            InputProps={{
              startAdornment: <InputAdornment position="start">🌽</InputAdornment>,
            }}
            onChange={changed}
          />
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => props.onSubmit(paymentAmount)}>Make Payment</Button>
      </CardActions>
    </Card>
  );
};

export default MakePayment;
