import React from "react";
import useContracts from "./useContracts";

const useFundedLoans = () => {
  const { loanRegistry } = useContracts();

  // const [resultsPerPage, setResultsPerPage] = React.useState<number>(numPerPage || 10);
  const [fundedLoanAddresses, setFundedLoanAddresses] = React.useState<string[]>([]);
  const [page, setPage] = React.useState<number>(0);

  React.useEffect(() => {
    if (!loanRegistry) { return; }

    loanRegistry.methods.getFundedLoans(0).call().then((result: any) => {
      setFundedLoanAddresses(result);
    });
  }, [loanRegistry, page]);

  return [fundedLoanAddresses, setPage];
};

export default useFundedLoans;
