import { Grid, Typography } from "@material-ui/core";
import * as React from "react";
import { useBlockNumber } from "../helpers";
import useEthRPCStore from "../stores/useEthRPCStore";
import { History } from "history";
import { useContractKit } from "@celo-tools/use-contractkit";
import useContracts from "../hooks/useContracts";
import {utils} from "ethers";

import JoinFundingPool from "../components/Funding/JoinFundingPool";
import ChartCard from "../components/ChartCard";

const unit = require("ethjs-unit"); //tslint:disable-line

interface IProps {
  match: {
    params: {
      address: string,
      block: string,
    };
  };
  history: History;
}

const FundingPool: React.FC<IProps> = ({ history }) => {
  const [erpc] = useEthRPCStore();
  const [blockNumber] = useBlockNumber(erpc);

  const {cUsd, fundingPool} = useContracts();

  // pool
  const [poolUsdBalance, setPoolUsdBalance] = React.useState<string>("0");
  const [fundedLoans, setFundedLoans] = React.useState<string[]>([]);

  // personal
  const [usdBalance, setUsdBalance] = React.useState<string>("0");
  const [fundingProvided, setFundingProvided] = React.useState<string>("0");

  const { getConnectedKit, address } = useContractKit();

  React.useEffect(() => {
    if (blockNumber === undefined || cUsd === undefined || fundingPool === undefined) {
      return;
    }

    cUsd.balanceOf(fundingPool._address).then((bal: any) => {
      setPoolUsdBalance(bal);
    });

    fundingPool.methods.getFundedLoans(0).call().then((ls: any) => {
      setFundedLoans(ls);
    });

    if (address) {
      cUsd.balanceOf(address).then((bal: any) => {
        setUsdBalance(bal);
      });

      fundingPool.methods.balanceOf(address).call().then((bal: any) => {
        setFundingProvided(bal);
      });
    }
  }, [blockNumber, address, cUsd, fundingPool]);

  const handleJoin = async (amount: string) => {
    const kkit = await getConnectedKit();
    const usdc = await kkit.contracts.getStableToken();
    const amnt = utils.parseUnits(amount, 18).toString();
    const approvalR = await usdc.approve(fundingPool._address, amnt)
      .sendAndWaitForReceipt({ gas: "20000000", gasPrice: "1000000000" });

    if (approvalR.status !== true) {
      console.error("Approval failed");
      console.error(approvalR);
    }
    const joinTxRaw = await fundingPool.methods.join(amnt);
    const joinTx = await kkit.sendTransactionObject(joinTxRaw, { gas: "20000000", gasPrice: "1000000000" });
    const joinR = await joinTx.waitReceipt();
    if (joinR.status !== true) {
      console.error("Join failed");
      console.error(joinR);
    }
  };

  const canJoin = () => {
    return !!address;
  };

  return (
    <Grid container spacing={3} direction="column">
      <Grid item container justify="space-between">
        <Grid item key="poolBalance">
          <ChartCard title="Pool Balance">
            <Typography variant="h4">{unit.fromWei(poolUsdBalance, "ether")} 💵</Typography>
          </ChartCard>
        </Grid>

        <Grid item key="fundedLoansCount">
          <ChartCard title="# Loans funded">
            <Typography variant="h4">{fundedLoans.length}</Typography>
          </ChartCard>
        </Grid>

        <Grid item key="fundedLoans">
          <ChartCard title="Funded Loans">
            {fundedLoans.map((fl) => {
              return (<Typography onClick={() => {history.push(`/loan/${fl}`); }}>{fl}</Typography>);
            })}
          </ChartCard>
        </Grid>

        <Grid item key="fundedLoanLinks">
        </Grid>
      </Grid>

      <Grid container item justify="space-around">
        {canJoin() ? <Grid item>
          <JoinFundingPool
            alreadyProviding={unit.fromWei(fundingProvided, "ether")}
            balance={unit.fromWei(usdBalance, "ether")}
            onSubmit={handleJoin} />
        </Grid> : null }
      </Grid>
    </Grid>
  );
};

export default FundingPool;
