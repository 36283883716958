import {
  AppBar,
  CssBaseline,
  Toolbar,
  IconButton,
  Grid,
  InputBase,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {utils} from "ethers";
import React, { Dispatch, ChangeEvent, KeyboardEvent, useState, useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import useDarkMode from "use-dark-mode";
import "./App.css";
import Address from "./containers/Address";
import Dashboard from "./containers/Dashboard";
import { darkTheme, lightTheme } from "./themes/jadeTheme";
import Brightness3Icon from "@material-ui/icons/Brightness3";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import CreateIcon from "@material-ui/icons/Create";
import useInterval from "use-interval";
import ETHJSONSpec from "@etclabscore/ethereum-json-rpc-specification/openrpc.json";
import { useTranslation } from "react-i18next";
import LanguageMenu from "./containers/LanguageMenu";
import { createBrowserHistory } from "history";
import ChainDropdown from "./components/ChainDropdown/ChainDropdown";
import { StringParam, QueryParamProvider, useQueryParams } from "use-query-params";
import { createPreserveQueryHistory } from "./helpers/createPreserveHistory";
import { IChain as Chain } from "./models/chain";
import useChainListStore from "./stores/useChainListStore";
import useEthRPCStore from "./stores/useEthRPCStore";
import AddChain from "./components/AddChain/AddChain";
import { useContractKit } from "@celo-tools/use-contractkit";
import WalletConnection from "./components/WalletConnection";
import CreateLoan from "./components/Loans/CreateLoan";
import { IUncreatedLoan } from "./models/loan";
import useContracts from "./hooks/useContracts";
import LoansList from "./containers/LoansList";
import FundingPool from "./containers/FundingPool";
import Loan from "./containers/Loan";

import AppBarDrawer from "./containers/AppBarDrawer";
import Swap from "./containers/Swap";

const history = createPreserveQueryHistory(createBrowserHistory, ["network", "rpcUrl"])();

function App() {
  const { t } = useTranslation();
  const darkMode = useDarkMode();
  const theme = darkMode.value ? darkTheme : lightTheme;

  const [search, setSearch] = useState();

  const [selectedChain, setSelectedChain] = useState<Chain>();
  const [chains, setChains] = useChainListStore<[Chain[], Dispatch<Chain[]>]>();
  const [ethRPC, setEthRPCChain] = useEthRPCStore();

  const [addChainDialogIsOpen, setAddChainDialogIsOpen] = useState<boolean>(false);
  const [createLoanDialogIsOpen, setCreateLoanDialogIsOpen] = useState<boolean>(false);
  const [createLoanLoadingState, setCreateLoanLoadingState] = useState<boolean>(false);

  const { connect, address, kit } = useContractKit();
  const {loanFactory} = useContracts();

  // default the selectedChain once chain list loads
  useEffect(() => {
    if (selectedChain !== undefined) { return; }
    if (chains === undefined) { return; }
    if (chains.length === 0) { return; }

    setSelectedChain(chains[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chains, selectedChain]);

  const [query, setQuery] = useQueryParams({
    network: StringParam,
    rpcUrl: StringParam,
  });

  // when url param is used to pick network,
  // keep things updated once chains list is loaded
  useEffect(() => {
    if (!chains || chains.length === 0) {
      return;
    }
    if (query.rpcUrl) {
      return;
    }

    if (query.network && selectedChain !== undefined) {
      if (query.network === selectedChain.name) {
        return;
      }
    }

    if (chains && query.network) {
      const foundChain = chains.find((chain: Chain) => chain.name === query.network);
      setSelectedChain(foundChain);
    } else {
      setSelectedChain(chains[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chains, query.network]);

  // keeps the window.location in sync with selected network
  useEffect(() => {
    if (selectedChain === undefined) {
      return;
    }
    const { name } = selectedChain as Chain;

    if (name !== query.network) {
      setQuery({ network: name });
      history.push({
        pathname: history.location.pathname,
        search: `?network=${name}`,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChain, setQuery]);

  // keep selected chain in sync with the current ethrpc instance
  useEffect(() => {
    if (selectedChain !== undefined) {
      setEthRPCChain(selectedChain);
    }
  }, [selectedChain, setEthRPCChain]);

  React.useEffect(() => {
    if (ethRPC) {
      ethRPC.startBatch();
    }
  }, [ethRPC]);

  useInterval(() => {
    if (ethRPC) {
      ethRPC.stopBatch();
      ethRPC.startBatch();
    }
  }, 100, true);

  const isAddress = (q: string): boolean => {
    const re = new RegExp(ETHJSONSpec.components.schemas.Address.pattern);
    return re.test(q);
  };

  const isKeccakHash = (q: string): boolean => {
    const re = new RegExp(ETHJSONSpec.components.schemas.Keccak.pattern);
    return re.test(q);
  };

  const isBlockNumber = (q: string): boolean => {
    const re = new RegExp(/^-{0,1}\d+$/);
    return re.test(q);
  };

  const handleSearch = async (qry: string | undefined) => {
    if (qry === undefined) { return; }
    const q = qry.trim();
    if (isAddress(q)) {
      history.push(`/address/${q}`);
    }
    if (isKeccakHash(q)) {
      let transaction;

      try {
        transaction = await ethRPC.eth_getTransactionByHash(q);
      } catch (e) {
        // do nothing
      }

      if (transaction) {
        history.push(`/tx/${q}`);
      }
      let block;
      try {
        block = await ethRPC.eth_getBlockByHash(q, false);
      } catch (e) {
        // do nothing
      }
      if (block) {
        history.push(`/block/${q}`);
      }
    }
    if (isBlockNumber(q)) {
      const block = await ethRPC.eth_getBlockByNumber(`0x${parseInt(q, 10).toString(16)}`, false);
      if (block) {
        history.push(`/block/${block.hash}`);
      }
    }
  };

  const openCreateLoanModal = () => {
    setCreateLoanDialogIsOpen(true);
  };

  const openAddChainModal = () => {
    setAddChainDialogIsOpen(true);
  };

  const cancelCreateLoanDialog = () => {
    setCreateLoanDialogIsOpen(false);
  };

  const cancelAddChainDialog = () => {
    setAddChainDialogIsOpen(false);
  };

  const submitCreateLoanDialog = async (l: IUncreatedLoan) => {
    setCreateLoanLoadingState(true);

    const amnt = utils.parseUnits(l.amount as string, 18);
    const txr = await loanFactory.methods.createLoan("CreditBuilder", amnt, l.duration, l.paymentFrequency, l.rate);
    const tx = await kit.sendTransactionObject(txr, { gas: "20000000", gasPrice: "1000000000" });
    const txrr = await tx.waitReceipt();
    if (txrr.status !== true) {
      console.error("checkpoint failed");
      console.error(txrr);
    }

    setCreateLoanLoadingState(false);
    setCreateLoanDialogIsOpen(false);
  };

  const submitAddChainDialog = (c: Chain) => {
    setAddChainDialogIsOpen(false);
    setChains(chains.concat(c));
    setSelectedChain(c);
  };

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <AppBar position="sticky" color="default" elevation={0}>
          <Toolbar>
            <Grid justify="space-between" alignItems="center" alignContent="center" container>
              <AppBarDrawer />
              <Grid item md={4} xs={6}>
                <InputBase
                  placeholder={t("Enter an Address")}
                  onKeyDown={
                    (event: KeyboardEvent<HTMLInputElement>) => {
                      if (event.keyCode === 13) {
                        handleSearch(search);
                      }
                    }
                  }
                  onChange={
                    (event: ChangeEvent<HTMLInputElement>) => {
                      if (event.target.value) {
                        const {value} = event.target;
                        setSearch(value as any);
                      }
                    }
                  }
                  fullWidth
                  style={{
                    background: "rgba(0,0,0,0.1)",
                    borderRadius: "4px",
                    padding: "5px 10px 0px 10px",
                    marginRight: "5px",
                  }}
                />
              </Grid>
              <Grid item>
                <WalletConnection
                  address={address}
                  onConnect={connect}
                  onExplore={() => history.push(`/address/${address}`) }
                />
              </Grid>

              <Grid item>
                <Tooltip title={t("Create Loan") as string}>
                  <IconButton onClick={openCreateLoanModal}>
                    <CreateIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item>
                {selectedChain ? <ChainDropdown
                                   chains={chains}
                                   onChange={setSelectedChain}
                                   selected={selectedChain} />
                : <CircularProgress />}
                <Tooltip title={t("Add custom chain") as string}>
                  <IconButton onClick={openAddChainModal}>
                    <PlaylistAddIcon />
                  </IconButton>
                </Tooltip>
                <LanguageMenu />
                <Tooltip title={t("Toggle Dark Mode") as string}>
                  <IconButton onClick={darkMode.toggle}>
                    {darkMode.value ? <Brightness3Icon /> : <WbSunnyIcon />}
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <AddChain
          open={addChainDialogIsOpen}
          onCancel={cancelAddChainDialog}
          onSubmit={submitAddChainDialog}
        />
        <CreateLoan
          open={createLoanDialogIsOpen}
          onCancel={cancelCreateLoanDialog}
          onSubmit={submitCreateLoanDialog}
          isLoading={createLoanLoadingState}
        />
        <div style={{ margin: "0px 25px 0px 25px" }}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <CssBaseline />
            <Switch>
              <Route path={"/"} component={Dashboard} exact={true} />
              <Route path={"/loans"} component={LoansList} />
              <Route path={"/loan/:address"} component={Loan} />
              <Route path={"/address/:address"} component={Address} />
              <Route path={"/funding"} component={FundingPool} />
              <Route path={"/swap"} component={Swap} />
            </Switch>
          </QueryParamProvider>
        </div>
      </ThemeProvider >
    </Router >
  );
}

export default App;
