import * as React from "react";
import AppBarButton from "../components/AppBar/AppBarButton";
import AppBarDrawer from "../components/AppBar/AppBarDrawer";
import { useContractKit } from "@celo-tools/use-contractkit";
import { useHistory } from "react-router-dom";

export default function AppBarDrawerWrapper() {
  const { address } = useContractKit();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNav = (ref: string) => {
    handleDrawerClose();

    if (ref === "dashboard") {
      history.push("/");
    } else if (ref === "funding") {
      history.push("/funding");
    } else if (ref === "swap") {
      history.push("/swap");
    } else if (ref === "loans") {
      history.push("/loans");
    } else if (ref === "account") {
      history.push(`/address/${address}`);
    } else {
      console.error("Unhandled nav ref: " + ref);
    }
  };

  return (
    <>
      <AppBarButton
        open={handleDrawerOpen}
        isOpen={open}
      />
      <AppBarDrawer
        account={address}
        navigate={handleNav}
        close={handleDrawerClose}
        isOpen={open}
      />
    </>
  );
}
