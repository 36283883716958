
import { CircularProgress, Grid } from "@material-ui/core";
import useEthRPCStore from "../stores/useEthRPCStore";
import * as React from "react";

import { useContractKit } from "@celo-tools/use-contractkit";

import useContracts from "../hooks/useContracts";

import LoanView from "../components/Loans/LoanView";

import useLoans from "../hooks/useLoans";
import StartLoan from "../components/Loans/Start";
import MakePayment from "../components/Loans/MakePayment";
import { utils } from "ethers";
import { useBlockNumber } from "../helpers";
import Finalize from "../components/Loans/Finalize";

interface IProps {
  match: {
    params: {
      address: string,
    };
  };
}

export default function Loan(props: IProps) {
  const addr = props.match.params.address;

  const [erpc] = useEthRPCStore();
  const [blockNumber] = useBlockNumber(erpc);

  const { getConnectedKit, address } = useContractKit();
  const [[loan]] = useLoans([addr]);
  const {cUsd} = useContracts();

  const [usdBalance, setUsdBalance] = React.useState<string>("0");

  React.useEffect(() => {
    if (!cUsd || !address) { return; }

    cUsd.balanceOf(address).then((b: any) => {
      setUsdBalance(b);
    });
  }, [blockNumber, cUsd, address]);

  if (!loan) { return (<CircularProgress />); }

  const start = async () => {
    const kit = await getConnectedKit();
    const txr = await loan.contract.methods.startLoan();
    const tx = await kit.sendTransactionObject(txr, { gas: "20000000", gasPrice: "1000000000" });
    const r = await tx.waitReceipt();
    if (r.status !== true) {
      console.error("Start Loan failed");
      console.error(r);
    }
  };

  const makePayment = async (amount: string) => {
    const kit = await getConnectedKit();
    const cusd = await kit.contracts.getStableToken();
    const amnt = utils.parseUnits(amount, 18).toString();
    const approvalReceipt = await cusd
      .approve(loan.data.address, amnt)
      .sendAndWaitForReceipt({ gas: "20000000", gasPrice: "1000000000" });

    if (approvalReceipt.status !== true) {
      console.error("Approval failed");
      console.error(approvalReceipt);
    }

    const txr = await loan.contract.methods.makePayment(amnt);
    const tx = await kit.sendTransactionObject(txr, { gas: "20000000", gasPrice: "1000000000" });
    const r = await tx.waitReceipt();
    if (r.status !== true) {
      console.error("make payment failed");
      console.error(r);
    }
  };

  const finalize = async () => {
    const kit = await getConnectedKit();
    const txr = await loan.contract.methods.finalize();
    const tx = await kit.sendTransactionObject(txr, { gas: "20000000", gasPrice: "1000000000" });
    const r = await tx.waitReceipt();
    if (r.status !== true) {
      console.error("Finalize loan failed");
      console.error(r);
    }
  };

  return (
    <Grid container spacing={3} direction="column">
      <LoanView
        loan={loan}
      />

      {address === loan.data.borrower ? (
        <Grid item container justify="space-between">
          {loan.data.isFunded && loan.data.isStarted === false ? (
            <Grid item>
              <StartLoan onSubmit={() => start()} />
            </Grid>
          ) : null}

        {loan.data.isStarted ? (
          <Grid item>
            <MakePayment
              interestOwed={utils.formatEther(loan.data.interestOwed)}
              usdBalance={utils.formatEther(usdBalance.toString())}
              onSubmit={(amount) => makePayment(amount)}
            />
          </Grid>
        ) : null}

        {loan.data.isStarted ? (
          <Grid item>
            <Finalize onSubmit={finalize} />
          </Grid>
        ) : null}
        </Grid>
      ) : null}
    </Grid>
  );
}
