import React from "react";
import { IDelegation } from "../models/delegation";
import useContracts from "./useContracts";

const useDelegations = (): [IDelegation[], React.Dispatch<React.SetStateAction<string | null>>] => {
  const { stkCorn } = useContracts();

  const [address, setAddress] = React.useState<string | null>(null);
  const [delegations, setDelegations] = React.useState<IDelegation[]>([]);

  React.useEffect(() => {
    if (!stkCorn || !address) { return; }

    const dels: IDelegation[] = [];
    stkCorn.methods.getDelegatesForDelegee(address).call()
      .then((result: string[]) => {
        const delRatioProms = result.map((delegate) => stkCorn.methods.getDelegationRatio(address, delegate).call().then((ratio: string) => dels.push({ delegate, ratio })));

        Promise.all(delRatioProms).then(() => {
          setDelegations(dels);
        });
      });
  }, [stkCorn, address]);

  return [delegations, setAddress];
};

export default useDelegations;
