// import { useEffect } from "react";
// import { uniqBy } from "lodash";
import { IChain as Chain } from "../models/chain";
import React from "react";

// const mergeChainSets = (c1: Chain[], c2: Chain[]) => uniqBy(c1.concat(c2), "name");

const useChainList = () => {
  const [chains, setChains] = React.useState<Chain[]>([
    {
      name: "Celo",
      network: "alfajores",
      rpc: ["https://alfajores-forno.celo-testnet.org"],
    },
    {
      name: "Celo",
      network: "mainnet",
      rpc: ["https://alfajores-forno.celo-testnet.org"],
    },
  ]);

  return [chains, setChains];
};

export default useChainList;
