import * as React from "react";
import Typography from "@material-ui/core/Typography";
import LinkIcon from "@material-ui/icons/Link";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import shortAddress from "../helpers/shortAddress";

interface IProps {
  addr: string;
}

const AddressLink: React.FC<IProps> = (props) => {
  if (!props.addr)  {return (<></>); }
  const copy = () => {
    const textField = document.createElement("textarea");
    textField.innerText = props.addr;
    document.body.appendChild(textField);
    if (window.navigator.platform === "iPhone") {
      textField.setSelectionRange(0, 99999);
    } else {
      textField.select();
    }
    document.execCommand("copy");
    textField.remove();
  };
  return (
    <Grid container alignItems="center">
      <Grid item>
        <Typography>{shortAddress(props.addr)}</Typography>
      </Grid>

      <Grid item>
        <IconButton aria-label="Copy to Clipboard" onClick={copy}>
          <LinkIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default AddressLink;
