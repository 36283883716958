const shortened = (addr: string, w = 8): string => {
  const half = w / 2;
  const upped = addr.toUpperCase();
  const pre = upped.substring(2, 2 + half);
  const post = upped.substring(42 - half);

  return `0x${pre}...${post}`;
};

export default shortened;
