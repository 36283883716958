import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Card, CardActions, CardContent } from "@material-ui/core";

interface IProps {
  unpaid: string;
  balance: string;
  onSubmit: () => void;
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Withdraw: React.FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Withdrawing
        </Typography>
        <Typography variant="h5" component="h2">
          {props.balance.slice(0, 12)}🌽 is ready for withdrawal
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          ({props.unpaid}🌽 earned)
        </Typography>
        <Typography variant="body2" component="p">
          Will fail if you have not waited for a new checkpoint since unstaking.
          <br />
          <br />
          Next checkpoint in estimated 10 minutes
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={props.onSubmit}>Withdraw</Button>
      </CardActions>
    </Card>
  );
};

export default Withdraw;
