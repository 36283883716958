import React from "react";
import { createStore } from "reusable";
import { useContractKit } from "@celo-tools/use-contractkit";
import useDelegations from "../hooks/useDelegations";

export default createStore(() => {
  const { address } = useContractKit();
  const [delegations, setDelegee] = useDelegations();

  React.useEffect(() => {
    if (!address) { return; }
    setDelegee(address);
    // eslint-disable-next-line
  }, [address]);

  return {
    delegations: delegations,
  };
});
