import {
  Grid,
  Typography,
  CircularProgress,
  Button,
} from "@material-ui/core";
import useEthRPCStore from "../stores/useEthRPCStore";
import * as React from "react";
import { useBlockNumber } from "../helpers";
import ChartCard from "../components/ChartCard";

import AddressTable from "../components/AddressTable";

import { useContractKit } from "@celo-tools/use-contractkit";
import {utils} from "ethers";

import useContracts from "../hooks/useContracts";
import LoanList from "../containers/LoansList";
import LoansSummary from "../components/Loans/LoansSummary";
import useLoanCounts from "../hooks/useLoanCounts";

const useState = React.useState;

const Dashboard = () => {
  const { address, getConnectedKit } = useContractKit();

  const [erpc] = useEthRPCStore();
  const [blockNumber] = useBlockNumber(erpc);

  const {corn, stkCorn, cUsd, loanRegistry, contractList} = useContracts();

  // stats
  const [stakingCount, setStakingCount] = useState<number>();
  const [startedStakingCount, setStartedStakingCount] = useState<number>();
  const [startedUnstakingCount, setStartedUnstakingCount] = useState<number>();

  const [stakedTotal, setStakedTotal] = useState<string>();
  const [unpaidTotal, setUnpaidTotal] = useState<string>();

  const [lastCheckpointBlockNumber, setLastCheckpointBlockNumber] = useState<string>();

  // Loans ui
  const [unusedCornVoteBalance, setUnusedCornVoteBalance] = React.useState<string>("0");
  const loanCounts = useLoanCounts();

  const makeCheckpoint = async () => {
    const kkit = await getConnectedKit();
    const checkpointTxRaw = await stkCorn.methods.makeNextCheckpoint();
    const checkpointTx = await kkit.sendTransactionObject(checkpointTxRaw, { gas: "20000000", gasPrice: "1000000000" });
    const checkpointR = await checkpointTx.waitReceipt();
    if (checkpointR.status !== true) {
      console.error("checkpoint failed");
      console.error(checkpointR);
    }
  };

  const matchWithFunding = async () => {
    const kkit = await getConnectedKit();

    const collectR = await loanRegistry.methods.collectApprovals();
    const collectRR = await kkit.sendTransactionObject(collectR, { gas: "20000000", gasPrice: "1000000000" });
    const collectReceipt = await collectRR.waitReceipt();
    if (collectReceipt.status !== true) {
      console.error("collecting approvals failed");
      console.error(collectReceipt);
    }

    const matchR = await loanRegistry.methods.matchWithFunding();
    const matchRR = await kkit.sendTransactionObject(matchR, { gas: "20000000", gasPrice: "1000000000" });
    const matchReceipt = await matchRR.waitReceipt();
    if (matchReceipt.status !== true) {
      console.error("matching failed");
      console.error(matchReceipt);
    }
  };

  React.useEffect(() => {
    if (!stkCorn) { return; }

    stkCorn.methods.getStakingCount().call().then((result: any) => {
      setStakingCount(result);
    });

    stkCorn.methods.getStartedStakingCount().call().then((result: any) => {
      setStartedStakingCount(result);
    });

    stkCorn.methods.getStartedUnstakingCount().call().then((result: any) => {
      setStartedUnstakingCount(result);
    });

    stkCorn.methods.stakedTotal().call().then((result: any) => {
      setStakedTotal(utils.formatUnits(result, 18));
    });

    stkCorn.methods.unpaidTotal().call().then((result: any) => {
      setUnpaidTotal(utils.formatUnits(result, 18));
    });

    stkCorn.methods.lastCheckpointBlockNumber().call().then((result: any) => {
      setLastCheckpointBlockNumber(result);
    });

    if (address) {
      stkCorn.methods.unusedCornVoteBalanceOf(address).call().then((bal: any) => {
        setUnusedCornVoteBalance(bal);
      });
    }// eslint-disable-next-line
  }, [stkCorn, blockNumber, cUsd, corn]);

  return (
    <div>
      <Grid container spacing={3} direction="column">
        <Grid item container justify="space-between">
          <Grid item key="stakingCount">
            <ChartCard title="Stakers">
              <Typography variant="h4">{stakingCount}</Typography>
            </ChartCard>
          </Grid>

          <Grid item key="startedStakingCount">
            <ChartCard title="Started Staking">
              <Typography variant="h4">{startedStakingCount}</Typography>
            </ChartCard>
          </Grid>

          <Grid item key="startedUnstakingCount">
            <ChartCard title="Started Unstaking">
              <Typography variant="h4">{startedUnstakingCount}</Typography>
            </ChartCard>
          </Grid>

          <Grid item key="stakedTotal">
            <ChartCard title="Total CORN Staked">
              <Typography variant="h4">{stakedTotal}</Typography>
            </ChartCard>
          </Grid>

          <Grid item key="unpaidTotal">
            <ChartCard title="Unpaid Rewards">
              <Typography variant="h4">{unpaidTotal}</Typography>
            </ChartCard>
          </Grid>

          <Grid item key="checkpointBlockNumber">
            <ChartCard title="Blocks Since Last Checkpoint">
              <Typography variant="h4">{blockNumber - parseInt(lastCheckpointBlockNumber as string, 10)}</Typography>
            </ChartCard>
          </Grid>

          <LoansSummary
            applicationCount={loanCounts.applications}
            approvedCount={loanCounts.approved}
            fundedCount={loanCounts.funded}
          />

        </Grid>

        <Grid item container justify="space-around">
          <Grid item key="addresses">
            <ChartCard title="Addresses">
              <AddressTable contracts={contractList} />
            </ChartCard>
          </Grid>

          <Grid item key="makeCheckpoint">
            <ChartCard title="Manual Checkpoint">
              <Typography variant="subtitle2" gutterBottom> Manually create a new checkpoint </Typography>
              {5 < (blockNumber - parseInt(lastCheckpointBlockNumber as string, 10)) ? (
                address ? (
                <Grid item>
                  <Button onClick={makeCheckpoint}>Make Checkpoint!</Button>
                </Grid>
              ) :
                <Grid>
                  <CircularProgress size={10} />
                  <Typography variant="caption">Waiting for wallet connection</Typography>
                </Grid>
              ) : (
                <Grid>
                  <CircularProgress size={10} />
                  <Typography variant="caption">
                    {5 - (blockNumber -  parseInt(lastCheckpointBlockNumber as string, 10))}
                    blocks until a checkpoint can be made
                  </Typography>
                </Grid>
              )}
            </ChartCard>
          </Grid>

          <Grid item key="autoFundingMatch">
            <ChartCard title="Auto-match funding">
              <Typography variant="subtitle2" gutterBottom>Match approved loans with funding</Typography>
              {address ? (
                <Grid item>
                  <Button onClick={matchWithFunding}>Match</Button>
                </Grid>
              ) : (
                <Grid>
                  <CircularProgress size={10} />
                  <Typography variant="caption">waiting for wallet connection</Typography>
                </Grid>
              )}
            </ChartCard>
          </Grid>
        </Grid>

        <Grid item container justify="space-around">
          <LoanList cornVoteBalance={unusedCornVoteBalance}></LoanList>
        </Grid>
      </Grid>

    </div>
  );
};

export default Dashboard;
