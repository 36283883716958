import { CircularProgress, Grid, Typography } from "@material-ui/core";
import ChartCard from "../ChartCard";
import {IWrappedLoan} from "../../models/loan";
import { utils } from "ethers";

interface IProps {
  loan: IWrappedLoan;
}

export default function LoanView(props: IProps) {
  if (!props.loan) {
    return <CircularProgress />;
  }

  const {
    address,
    amount,
    borrower,
    rate,
    balance,
    interestOwed,
    interestPaid,
    pool,
  } = props.loan.data;

  return (
    <Grid item container justify="space-between">
      <Grid item key="loanAddress">
        <ChartCard title="Loan Address">
          <Typography variant="h4">{address}</Typography>
        </ChartCard>
      </Grid>

      <Grid item key="poolAddress">
        <ChartCard title="Funding Pool Address">
          <Typography variant="h4">{pool}</Typography>
        </ChartCard>
      </Grid>

      <Grid item key="loanAmount">
        <ChartCard title="Loan Amount">
          <Typography variant="h4">{utils.formatEther(amount)}</Typography>
        </ChartCard>
      </Grid>

      <Grid item key="borrowerAddress">
        <ChartCard title="Borrower Address">
          <Typography variant="h4">{borrower}</Typography>
        </ChartCard>
      </Grid>

      <Grid item key="loanRate">
        <ChartCard title="Loan Rate">
          <Typography variant="h4">{rate}</Typography>
        </ChartCard>
      </Grid>

      <Grid item key="balance">
        <ChartCard title="USDC Balance">
          <Typography variant="h4">{utils.formatEther(balance)}</Typography>
        </ChartCard>
      </Grid>

      <Grid item key="owing">
        <ChartCard title="Currently Owing">
          <Typography variant="h4">{utils.formatEther(interestOwed)}</Typography>
        </ChartCard>
      </Grid>

      <Grid item key="totalPaid">
        <ChartCard title="Total Paid">
          <Typography variant="h4">{utils.formatEther(interestPaid)}</Typography>
        </ChartCard>
      </Grid>
    </Grid>
  );
  /* Loan Address: {props.loan.data.address}
   *    Loan Amount: {props.loan.data.amount}
   *    Borrower Address: {props.loan.data.borrower}
     Votes Assigned: {props.loan.data.cornVotes}
     Rate: {props.loan.data.rate}
   *  Loan State // todo later
   *   Funding Pool assigned
   *   Balance info:
   *   - Current amount owed
   *   - Total paid
   *   - Overpayments?
   *   Loan controls:
   *   - make payment
   *   - finalize */

}
