import React from "react";
import { useContractKit } from "@celo-tools/use-contractkit";

import { TestMasa, StkCorn } from "@masa-finance/smart-contracts";
import LoanFactory from "@masa-finance/smart-contracts/build/contracts/LoanFactory.json";
import LoanRegistry from "@masa-finance/smart-contracts/build/contracts/LoanRegistry.json";
import FundingPool from "@masa-finance/smart-contracts/build/contracts/FundingPool.json";

const testMasaAddress = TestMasa.networks["44787"].address;
const stkCornAddress = StkCorn.networks["44787"].address;
const loanFactoryAddress = LoanFactory.networks["44787"].address;
const loanRegistryAddress = LoanRegistry.networks["44787"].address;
const fundingPoolAddress = FundingPool.networks["44787"].address;

const testMasaAbi = TestMasa.abi;
const stkCornAbi = StkCorn.abi;
const loanFactoryAbi = LoanFactory.abi;
const loanRegistryAbi = LoanRegistry.abi;
const fundingPoolAbi = FundingPool.abi;

const useContracts = () => {
  const { kit } = useContractKit();

  const [contractList, setContractList] = React.useState<any>([]);

  const [corn, setCorn] = React.useState<any>(new kit.web3.eth.Contract(
    testMasaAbi as any,
    testMasaAddress,
  ));

  const [stkCorn, setStkCorn] = React.useState<any>(new kit.web3.eth.Contract(
    stkCornAbi as any,
    stkCornAddress,
  ));

  const [loanFactory, setLoanFactory] = React.useState<any>(new kit.web3.eth.Contract(
    loanFactoryAbi as any,
    loanFactoryAddress,
  ));

  const [loanRegistry, setLoanRegistry] = React.useState<any>(new kit.web3.eth.Contract(
    loanRegistryAbi as any,
    loanRegistryAddress,
  ));

  const [fundingPool, setFundingPool] = React.useState<any>(new kit.web3.eth.Contract(
    fundingPoolAbi as any,
    fundingPoolAddress,
  ));

  const [cUsd, setCUsd] = React.useState<any>();

  React.useEffect(() => {
    const cl = [
      {name: "Corn", address: corn._address},
      {name: "StkCorn", address: stkCorn._address},
      {name: "LoanFactory", address: loanFactoryAddress},
      {name: "LoanRegistry", address: loanRegistryAddress},
      {name: "fundingPool", address: fundingPoolAddress, link: "/funding" },
    ];

    kit.contracts.getStableToken().then((result: any) => {
      cl.push({name: "cUsd", address: result.address});
      setCUsd(result);
      setContractList(cl);
    });

    setCorn(new kit.web3.eth.Contract(
      testMasaAbi as any,
      testMasaAddress,
    ));

    setStkCorn(new kit.web3.eth.Contract(
      stkCornAbi as any,
      stkCornAddress,
    ));

    setLoanFactory(new kit.web3.eth.Contract(
      loanFactoryAbi as any,
      loanFactoryAddress,
    ));

    setLoanRegistry(new kit.web3.eth.Contract(
      loanRegistryAbi as any,
      loanRegistryAddress,
    ));

    setFundingPool(new kit.web3.eth.Contract(
      fundingPoolAbi as any,
      fundingPoolAddress,
    ));
    // eslint-disable-next-line
  }, [kit]);

  return { corn, stkCorn, cUsd, loanFactory, loanRegistry, fundingPool, contractList };
};

export default useContracts;
