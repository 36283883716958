import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { ReusableProvider } from "reusable";
import "./i18n";
import { ContractKitProvider, Alfajores, NetworkNames } from "@celo-tools/use-contractkit";
import "@celo-tools/use-contractkit/lib/styles.css";

import masaLogo from "./masa.png";

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <ContractKitProvider
      dapp={{
        name: "Masa Contract Stats",
        description: "View stats relating to masa contracts",
        url: window.location.origin,
        icon: masaLogo,
      }}
      networks={[Alfajores]}
      network={{
        name: NetworkNames.Alfajores,
        rpcUrl: "https://alfajores-forno.celo-testnet.org",
        graphQl: "https://alfajores-blockscout.celo-testnet.org/graphiql",
        explorer: "https://alfajores-blockscout.celo-testnet.org",
        chainId: 44787,
      }}
    >
      <ReusableProvider>
        <App />
      </ReusableProvider>
    </ContractKitProvider>
  </I18nextProvider>,
  document.getElementById("root") as HTMLElement,
);
