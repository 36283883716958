import * as React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddressLink from "../AddressLink";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import Delete from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import { IDelegation } from "../../models/delegation";
import { Save } from "@material-ui/icons";

interface IProps {
  canEdit: Boolean;
  delegations: IDelegation[];
  onUpdate: (delegate: string, ratio: string) => void;
  onRemove: (delegate: string) => void;
}

const useStyles = makeStyles({
  table: {
    minWidth: 250,
  },
});

const DelegationList: React.FC<IProps> = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const [ratioChanged, setRatioChanged] = React.useState<boolean>(false);
  const [ratio, setRatio] = React.useState<string>("");

  const goToDelegate = (delegate: string) => {
    history.push(`/address/${delegate}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Delegate Address</TableCell>
            <TableCell align="right">Ratio</TableCell>
            <TableCell align="right">View</TableCell>
            <TableCell align="right">Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.delegations.map((delegation) => (
            <TableRow key={delegation.delegate}>
              <TableCell component="th" scope="row">
                <AddressLink addr={delegation.delegate} />
              </TableCell>

              <TableCell align="right">
                <TextField
                  type="text"
                  variant="filled"
                  value={ratio || delegation.ratio}
                  InputProps={{
                    endAdornment: (ratioChanged ? (<InputAdornment position="end">
                      <IconButton
                        aria-label="Save"
                        onClick={() => props.onUpdate(delegation.delegate, ratio)}
                      >
                        <Save />
                      </IconButton>
                    </InputAdornment>) : null),
                  }}
                  onChange={(e) => {
                    setRatio(e.target.value);
                    setRatioChanged(true);
                  }}
                />
              </TableCell>

              <TableCell align="right">
                <IconButton
                  aria-label={`view ${delegation.delegate} in-app`}
                  onClick={() => goToDelegate(delegation.delegate)}
                >
                  <Visibility />
                </IconButton>
              </TableCell>

              <TableCell align="right">
                <IconButton
                  aria-label={`remove delegation to ${delegation.delegate}`}
                  onClick={() => props.onRemove(delegation.delegate)}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DelegationList;
