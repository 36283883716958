import React from "react";
import { createStore } from "reusable";
import useLoanApplications from "../hooks/useLoanApplications";
import useLoanApprovals from "../hooks/useLoanApprovals";
import useFundedLoans from "../hooks/useFundedLoans";
import useLoans from "../hooks/useLoans";

export default createStore(() => {
  const [loanApplicationAddresses] = useLoanApplications();
  const [loanApplicationContracts, setLoanApplicationContracts] = useLoans();

  const [loanApprovalAddresses] = useLoanApprovals();
  const [loanApprovalContracts, setLoanApprovalContracts] = useLoans();

  const [fundedLoanAddresses] = useFundedLoans();
  const [fundedLoanContracts, setFundedLoanContracts] = useLoans();

  React.useEffect(() => {
    if (loanApplicationAddresses.length < 0) { return; }

    setLoanApplicationContracts(loanApplicationAddresses);
    // eslint-disable-next-line
  }, [loanApplicationAddresses]);

  React.useEffect(() => {
    if (loanApprovalAddresses.length < 0) { return; }

    setLoanApprovalContracts(loanApprovalAddresses);
    // eslint-disable-next-line
  }, [loanApprovalAddresses]);

  React.useEffect(() => {
    if (fundedLoanAddresses.length < 0) { return; }

    setFundedLoanContracts(fundedLoanAddresses);
    // eslint-disable-next-line
  }, [fundedLoanAddresses]);

  return {
    loanApplications: loanApplicationContracts,
    loanApprovals: loanApprovalContracts,
    fundedLoans: fundedLoanContracts,
  };
});
