import clsx from "clsx";
import { IconButton, Typography } from "@material-ui/core";
import { makeStyles, useTheme, Theme, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

interface IProps {
  open: () => void;
  isOpen: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
  }),
);

export default function AppBarButton(props: IProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <IconButton
        color="inherit"
        aria-label={t("open drawer")}
        onClick={props.open}
        edge="start"
        className={clsx(classes.menuButton, props.isOpen && classes.hide)}
      >
        🌽
      </IconButton>
      <Typography color="textSecondary" variant="h6">
        Masa
      </Typography>
    </>
  );
}
