import { CircularProgress, Grid } from "@material-ui/core";
import { useContractKit } from "@celo-tools/use-contractkit";
import useContracts from "../hooks/useContracts";
import LoanList from "../components/Loans/LoanList";
import {utils} from "ethers";

import useLoans from "../stores/useLoans";
import useCornVoteBalance from "../hooks/useCornVoteBalance";

export default function LoansList(props: any) {
  const {loanApplications, loanApprovals, fundedLoans} = useLoans();
  const cornVoteBalances = useCornVoteBalance();

  const masa = useContracts();
  const {getConnectedKit} = useContractKit();

  const handleVoting = async (loanAddress: string, amount: string) => {
    const kit = await getConnectedKit();
    const txRaw = await masa.stkCorn.methods.assignVotes(loanAddress, utils.parseUnits(amount, 18));
    const tx = await kit.sendTransactionObject(txRaw, { gas: "20000000", gasPrice: "1000000000" });
    const receipt = await tx.waitReceipt();
    if (receipt.status !== true) {
      console.error("Voting failed");
      console.error(receipt);
    }
    return true;
  };

  if (!loanApplications && !loanApprovals) { return (<CircularProgress />); }

  const loans = loanApplications.concat(loanApprovals).concat(fundedLoans).map((l: any) => l.data);

  return (
    <Grid container>
      <LoanList
        loans={loans}
        onVote={handleVoting}
        cornVoteBalance={cornVoteBalances.unused}
      />
    </Grid>
  );
}
