import * as React from "react";
import { Typography, Card, CardContent, Chip } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export interface IAddressViewProps {
  address: string;
  txCount: number;
  nativeBalance: string;
  usdBalance: string;
  cornBalance: string;
  stakedBalance: string;
  stakingRewards: string;
  isStaking: boolean;
  isStartedUnstaking: boolean;
  unusedCornVoteBalance: string;
  usedCornVoteBalance: string;
}

function AddressView(props: IAddressViewProps) {
  const {
    address,
    nativeBalance,
    txCount,
    usdBalance,
    cornBalance,
    stakedBalance,
    stakingRewards,
    unusedCornVoteBalance,
    usedCornVoteBalance,
  } = props;
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent>
        {props.isStaking ? (
          <Chip icon={<Typography>🥩</Typography>} label="Staking" color="primary" />
        ) : null}

        {props.isStartedUnstaking ? (
          <Chip icon={<Typography>☟🥩</Typography>} label="Unstaking" color="secondary" />
        ) : null}
        <Typography variant="h6">{t("Address")}: {address}</Typography>
        <Typography variant="h6">{t("Native Balance (celo)")}: {nativeBalance}</Typography>
        <Typography variant="h6">{t("cUSD Balance")}: {usdBalance}$</Typography>
        <Typography variant="h6">{t("CORN Balance")}: {cornBalance}🌽</Typography>
        <Typography variant="h6">{t("Staked CORN")}: {stakedBalance}🥩🌽</Typography>
        <Typography variant="h6">{t("Assigned CornVote Balance")}: {usedCornVoteBalance}🌽🗳️</Typography>
        <Typography variant="h6">{t("Unassigned CornVote Balance")}: {unusedCornVoteBalance}🌽🗳️</Typography>
        <Typography variant="h6">{t("Unpaid Staking Rewards")}: {stakingRewards}🥩🌽</Typography>
        <Typography variant="h6">{t("Transaction Count")}: {txCount}</Typography>
        <br />
      </CardContent>
    </Card>
  );
}

export default AddressView;
