import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Card, CardActions, CardContent, TextField, Grid } from "@material-ui/core";

interface IProps {
  alreadyDelegatedRatio: string;
  onSubmit: (delegate: string, ratio: string) => void;
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Delegation: React.FC<IProps> = (props) => {
  const classes = useStyles();

  const [delegate, setDelegate] = React.useState<string>();
  const [ratio, setRatio] = React.useState<string>();

  const hasDelegatedMax = () => props.alreadyDelegatedRatio !== "100";
  const handleSubmit = () => {
    if (!delegate || !ratio) { return; }
    props.onSubmit(delegate, ratio);
  }

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
         Delegation
        </Typography>
        <Typography variant="h5" component="h2">
          {hasDelegatedMax() ? `You can delegate up to ${100 - parseInt(props.alreadyDelegatedRatio, 10)}` : `You are delegating all of your votes.` }
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {props.alreadyDelegatedRatio}% already delegated
        </Typography>
        <Typography variant="body2" component="p">
          <Grid container>
            <Grid item>
              <TextField
                label="Delegate Address"
                type="text"
                variant="filled"
                value={delegate}
                onChange={(e) => setDelegate(e.target.value)}
              />
              |
              <TextField
                label="Ratio to Delegate"
                type="text"
                variant="filled"
                value={ratio}
                onChange={(e) => setRatio(e.target.value)}
              />
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleSubmit}>Add Delegate</Button>
      </CardActions>
    </Card>
  );
};

export default Delegation;
