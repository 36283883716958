import { Grid, Typography } from "@material-ui/core";
import ChartCard from "../ChartCard";

interface IProps {
  applicationCount: number;
  approvedCount: number;
  fundedCount: number;
}

const LoansSummary: React.FC<IProps> = (props) => {
  return (
    <>
      <Grid item key="applicationCount">
        <ChartCard title="Loan Applications">
          <Typography variant="h4">{props.applicationCount}</Typography>
        </ChartCard>
      </Grid>

      <Grid item key="approvedCount">
        <ChartCard title="Loans Approved">
          <Typography variant="h4">{props.approvedCount}</Typography>
        </ChartCard>
      </Grid>

      <Grid item key="fundedCount">
        <ChartCard title="Loans Funded">
          <Typography variant="h4">{props.fundedCount}</Typography>
        </ChartCard>
      </Grid>
    </>
  );
};

export default LoansSummary;
