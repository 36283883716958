import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { CircularProgress, Divider } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import {utils} from "ethers";

interface IProps {
  loan: any;
  cornVoteBalance?: string;
  votesRequired: string;
  open: boolean;
  onCancel: () => void;
  onSubmit: (votes: string) => void;
  isLoading: boolean;
}

const LoanVoting: React.FC<IProps> = (props: IProps) => {
  const [amount, setAmount] = React.useState<string>("0");

  const handleSubmit = () => {
    if (amount === "0") { return; }

    props.onSubmit(amount);
  };

  return (
    <>
      <Dialog open={props.open} onClose={props.onCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Loan Voting</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vote on a loan for it to be approved. Loans that reach maturity will yeild
            rewards to those who voted. Loans that default will result in CornVote
            penalties, affecting staking returns.
          </DialogContentText>

          <DialogContentText>
            This loan requires {utils.formatEther(props.votesRequired)} more votes to be approved.
            <Divider />
            { props.cornVoteBalance === undefined ? "connect your wallet in order to vote" : `you have ${utils.formatEther(props.cornVoteBalance)} Votes available` }
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="amount"
            label="Amount (in CornVotes)"
            type="text"
            fullWidth
            onChange={(v) => setAmount(v.target.value) }
          />
        </DialogContent>
        { props.isLoading ? <CircularProgress /> : (
            <DialogActions>
              <Button onClick={props.onCancel} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Assign Votes
              </Button>
            </DialogActions>
          )}
      </Dialog>
    </>
  );
};

export default LoanVoting;
