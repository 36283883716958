import clsx from "clsx";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Drawer,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme, Theme, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import {
  ChevronLeft as ChevronLeftIcon,
  Dashboard as DashboardIcon,
  AccountBalance as FundingIcon,
  SwapHoriz as SwapIcon,
  AccountBalanceWallet as AccountIcon,
  LocalAtm as LoansIcon,
} from "@material-ui/icons";

import shortAddress from "../../helpers/shortAddress";

interface IProps {
  account: string | null;
  navigate: (ref: string) => void;
  close: () => void;
  isOpen: boolean;
}

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1, 0, 3),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "space-between",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);

export default function AppBarDrawer(props: IProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme as any);

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={props.isOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <Typography color="textSecondary" variant="h6">
          🌽 &nbsp;&nbsp;&nbsp; Masa
        </Typography>
        <IconButton onClick={props.close}>
          <ChevronLeftIcon />
        </IconButton>
      </div>

      <Divider />

      <List>
        <ListItem button key="Dashboard" onClick={() => props.navigate("dashboard")}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={t("Dashboard")} />
        </ListItem>

        <ListItem button key="Funding" onClick={() => props.navigate("funding")}>
          <ListItemIcon>
            <FundingIcon />
          </ListItemIcon>
          <ListItemText primary={t("Funding")} />
        </ListItem>

        <ListItem button key="Swap" onClick={() => props.navigate("swap")}>
          <ListItemIcon>
            <SwapIcon />
          </ListItemIcon>
          <ListItemText primary={t("Swap")} />
        </ListItem>

        <ListItem button key="Loans" onClick={() => props.navigate("loans")}>
          <ListItemIcon>
            <LoansIcon />
          </ListItemIcon>
          <ListItemText primary={t("Loans")} />
        </ListItem>
      </List>

      <Divider />

      <List className={ clsx(props.account === null && classes.hide) }>
        <ListItem button key="Account" onClick={() => props.navigate("account")}>
          <ListItemIcon>
            <AccountIcon />
          </ListItemIcon>
          <ListItemText
            primary={t("My Account")}
            secondary={props.account ? shortAddress(props.account, 12) : ""}
          />
        </ListItem>
      </List>
    </Drawer>
  );
}
