
import React from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import LinkOffIcon from "@material-ui/icons/LinkOff";
import LinkOnIcon from "@material-ui/icons/Link";

interface IProps {
  address: string | null;
  onConnect: () => any;
  onExplore: () => any;
}

const WalletConnection: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();

  if (!props.address) {
    return (
      <Button variant="outlined" onClick={props.onConnect} endIcon={<LinkOffIcon />}>
        {t("Wallet Not Connected")}
      </Button>
    );
  }

  return (
    <Button variant="outlined" onClick={props.onExplore} endIcon={<LinkOnIcon />}>
      {props.address.slice(0, 10)}...
    </Button>
  );
};

export default WalletConnection;
