import { useContractKit } from "@celo-tools/use-contractkit";
import React from "react";
import { useBlockNumber } from "../helpers";
import useEthRPCStore from "../stores/useEthRPCStore";
import useContracts from "./useContracts";

const useCornVoteBalance = () => {
  const { address } = useContractKit();
  const [erpc] = useEthRPCStore();
  const [blockNumber] = useBlockNumber(erpc);

  const { stkCorn } = useContracts();

  const [used, setUsed] = React.useState<string>();
  const [unused, setUnused] = React.useState<string>();

  React.useEffect(() => {
    if (!stkCorn) { return; }
    if (!blockNumber) { return; }
    if (!address) { return; }

    stkCorn.methods.usedCornVoteBalanceOf(address).call().then((bal: any) => {
      setUsed(bal);
    });

    stkCorn.methods.unusedCornVoteBalanceOf(address).call().then((bal: any) => {
      setUnused(bal);
    });
  }, [stkCorn, blockNumber, address]);

  return { used, unused };
};

export default useCornVoteBalance;
