
import {
  useTheme,
  Theme,
  CircularProgress,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import * as React from "react";
import { useBlockNumber } from "../helpers";
import useEthRPCStore from "../stores/useEthRPCStore";
import { History } from "history";
import { useContractKit } from "@celo-tools/use-contractkit";
import useContracts from "../hooks/useContracts";
import {utils} from "ethers";
import SwapIcon from "@material-ui/icons/SwapHoriz";

import {BigNumber} from "ethers";

import {Ubeswap} from "@masa-finance/smart-contracts";
import ChartCard from "../components/ChartCard";
import { VictoryLine, VictoryChart } from "victory";
import getTheme from "../themes/victoryTheme";

const useState = React.useState;

interface IProps {
  match: {
    params: {
      address: string,
      block: string,
    };
  };
  history: History;
}

const config = {
  priceHistoryLength: 100,
  chartHeight: 400,
  chartWidth: 800,
};

const Swap: React.FC<IProps> = ({ match, history }) => {
  const theme = useTheme<Theme>();
  const victoryTheme = getTheme(theme);
  const [erpc] = useEthRPCStore();
  const [blockNumber] = useBlockNumber(erpc);

  const {corn, cUsd} = useContracts();

  const [swapContracts, setSwapContracts] = useState<any>();
  const [swapPair, setSwapPair] = useState<any>();

  const [swapDirection, setSwapDirection] = useState<string>("buy");
  const [swapAmount, setSwapAmount] = useState<string>();
  const [exchangeRate, setExchangeRate] = useState<string>();
  const [expectedReturn, setExpectedReturn] = useState<string>();

  const { kit, getConnectedKit, address } = useContractKit();

  const [exchangePriceHistory, setExchangePriceHistory] = useState<any[]>([]);

  React.useEffect(() => {
    if (!kit) { return; }
    setSwapContracts(Ubeswap("alfajores", kit));
  }, [kit]);

  React.useEffect(() => {
    if (!blockNumber) { return; }
    if (!kit) { return; }
    if (!swapPair) { return; }
    if (!cUsd) { return; }

    swapPair.methods.getReserves().call().then(async ({reserve0, reserve1}: any) => {
      const one = utils.parseUnits("1", 18).toString();
      const rate = await swapContracts.router.methods.quote(one, reserve0, reserve1).call();
      setExchangeRate(rate);
      setExchangePriceHistory([
        ...exchangePriceHistory,
        {
          x: blockNumber,
          y: utils.formatUnits(rate).slice(0, 7),
        },
      ]);
    });
    // eslint-disable-next-line
  }, [kit, cUsd, swapPair, blockNumber]);

  React.useEffect(() => {
    if (!swapContracts) { return; }
    if (!cUsd) { return; }

    swapContracts.factory.methods.getPair(corn._address, cUsd.address).call()
      .then((pairAddr: string) => {
        setSwapPair(new kit.web3.eth.Contract(swapContracts.pairAbi as any, pairAddr));
      });
    // eslint-disable-next-line
  }, [kit, cUsd, swapContracts]);

  const swapSell = async () => {
    const kkit = await getConnectedKit();
    const amnt = utils.parseUnits(swapAmount as string, 18).toString();
    const approvalTx = await corn.methods.approve(swapContracts.router._address, amnt);
    const approveTxRaw = await kkit.sendTransactionObject(
      approvalTx,
      { gas: "20000000", gasPrice: "1000000000" },
    );
    const approveR = await approveTxRaw.waitReceipt();

    if (approveR.status !== true) {
      console.error("Approval failed");
      console.error(approveR);
    }
    const path = [corn._address, cUsd.address];

    const swapR = await swapContracts.router.methods.swapExactTokensForTokens(
      amnt, "1",
      path,
      address,
      new Date().getTime() * 1000,
    );
    const swapRR = await kkit.sendTransactionObject(
      swapR,
      { gas: "20000000", gasPrice: "1000000000" },
    );
    const swapReceipt = await swapRR.waitReceipt();
    if (swapReceipt.status !== true) {
      console.error("Swap failed");
      console.error(swapReceipt);
    }
  };

  const swap = async () => {
    if (swapDirection === "sell") { return swapSell(); }
    const kkit = await getConnectedKit();
    const cusd = await kkit.contracts.getStableToken();
    const amnt = utils.parseUnits(swapAmount as string, 18).toString();
    const approvalReceipt = await cusd
      .approve(swapContracts.router._address, amnt)
      .sendAndWaitForReceipt({ gas: "20000000", gasPrice: "1000000000" });

    if (approvalReceipt.status !== true) {
      console.error("Approval failed");
      console.error(approvalReceipt);
    }
    const path = [cUsd.address, corn._address];

    const swapR = await swapContracts.router.methods.swapExactTokensForTokens(
      amnt,
      "1",
      path,
      address,
      new Date().getTime() * 1000,
    );
    const swapRR = await kkit.sendTransactionObject(
      swapR,
      { gas: "20000000", gasPrice: "1000000000" },
    );
    const swapReceipt = await swapRR.waitReceipt();
    if (swapReceipt.status !== true) {
      console.error("Swap failed");
      console.error(swapReceipt);
    }
  };

  const swapAmountChanged = (ev: any) => {
    setSwapAmount(ev.target.value);
    if (!exchangeRate) { return; }

    const amount = parseFloat(ev.target.value);
    if (amount === 0) { return; }

    if (swapDirection === "buy") {
      let res;
      if (amount < 1) {
        res = BigNumber.from(exchangeRate).div(1 / amount);
      } else {
        res = BigNumber.from(exchangeRate).mul(amount);
      }

      setExpectedReturn(utils.formatUnits(res));
    }
  };

  return (
    <Grid container key="swap">
      <Grid item container justify="space-between">
        <Grid item key="exchangeRate">
          <ChartCard title="Exchange Rate">
            {exchangeRate ?
             <Typography variant="h4">
               1$ = {utils.formatUnits(exchangeRate).slice(0, 18)} Corn
             </Typography>
            : <CircularProgress size={10} />}
          </ChartCard>
        </Grid>

        <ChartCard title="Price over time">
          <VictoryChart
            height={config.chartHeight}
            width={config.chartWidth}
            theme={victoryTheme as any}
          >
            <VictoryLine data={exchangePriceHistory} />
          </VictoryChart>
        </ChartCard>
      </Grid>

      {address ? (
        <Grid item>
          <Grid container>
            <Grid item>
              <Typography>Buy Corn</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={swapDirection === "sell"}
                onChange={() => setSwapDirection(swapDirection === "buy" ? "sell" : "buy") }
              />
            </Grid>
            <Grid>
              <Typography>Sell Corn</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography>
              Input how much {swapDirection === "buy" ? "cUSD" : "CORN"} you want to
              exchange for {swapDirection === "buy" ? "Corn" : "cUSD"}
            </Typography>
          </Grid>
          <Grid item container>
            <Grid item>
              <TextField
                label="cUSD amount to swap"
                type="number"
                variant="filled"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                onChange={swapAmountChanged}
              />
            </Grid>

            <Grid item>
              <SwapIcon />
            </Grid>

            <Grid item>
              <TextField
                label="Expected Return"
                type="text"
                variant="filled"
                InputProps={{
                  startAdornment: swapDirection === "buy" ?
                                  <InputAdornment position="start">🌽</InputAdornment>
                :
                                  <InputAdornment position="start">$</InputAdornment>,
                }}
                onChange={swapAmountChanged}
                value={expectedReturn}
              />
            </Grid>

            <Grid item>
              <Button onClick={swap}>
                Swap!
              </Button>
            </Grid>

          </Grid>
        </Grid>
      ) : (
        <Grid>
          <CircularProgress size={10} />
          <Typography variant="caption">waiting for wallet connection</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default Swap;
