import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { CircularProgress } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";

import { IUncreatedLoan, ILoanType } from "../../models/loan";
import { MenuItem, Select } from "@material-ui/core";

interface IProps {
  open: boolean;
  onCancel: () => void;
  onSubmit: (loan: IUncreatedLoan) => void;
  isLoading: boolean;
}

const CreateLoan: React.FC<IProps> = (props: IProps) => {
  const [loanType, setLoanType] = React.useState<ILoanType>("CreditBuilder");
  const [amount, setAmount] = React.useState<string>("0");
  const [duration, setDuration] = React.useState<string>("100000");
  const [rate, setRate] = React.useState<string>("100000");
  const [paymentFrequency, setPaymentFrequency] = React.useState<string>("5");

  const handleSubmit = () => {
    if (loanType === undefined || amount === "0" || duration === undefined) { return; }

    const loan: IUncreatedLoan = { type: loanType, amount, duration, paymentFrequency, rate };
    props.onSubmit(loan);
  };

  if (props.isLoading && props.open) {
    return (<CircularProgress />);
  }

  return (
    <>
      <Dialog open={props.open} onClose={props.onCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Loan</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Create a loan for validators to vote on.
            </DialogContentText>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={loanType}
                label="Loan Type"
                onChange={(ev) => setLoanType(ev.target.value as ILoanType)}
            >
              <MenuItem value={"CreditBuilder"}>Credit Builder</MenuItem>
              <MenuItem value={"LineOfCredit"}>Line of Credit</MenuItem>
            </Select>
          <TextField
            autoFocus
            margin="dense"
            id="amount"
            label="Amount (in cUSD)"
            type="text"
            fullWidth
            onChange={(v) => setAmount(v.target.value) }
          />
          <TextField
            margin="dense"
            id="duration"
            label="Duration (in blocks)"
            defaultValue="10000"
            type="text"
            fullWidth
            onChange={(v) => setDuration(v.target.value) }
          />
          <TextField
            margin="dense"
            id="rate"
            label="Interest Rate (0.00001% increments)"
            defaultValue="10000"
            type="text"
            fullWidth
            onChange={(v) => setRate(v.target.value) }
          />
          <TextField
            margin="dense"
            id="paymentFrequency"
            label="Payment Frequency"
            defaultValue="5"
            type="text"
            fullWidth
            onChange={(v) => setPaymentFrequency(v.target.value) }
          />
        </DialogContent>
        { props.isLoading ? <CircularProgress /> : (
          <DialogActions>
            <Button onClick={props.onCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Create
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default CreateLoan;
