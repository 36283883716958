import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table, { Padding } from "@material-ui/core/Table";
import Chip from "@material-ui/core/Chip";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import ViewIcon from "@material-ui/icons/Visibility";
import ThumbUp from "@material-ui/icons/ThumbUp";
import { useHistory } from "react-router-dom";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { LinearProgress } from "@material-ui/core";

import LoanVoting from "./LoanVoting";

import AddressLink from "../AddressLink";
import { ILoan, ILoanState } from "../../models/loan";

import {utils} from "ethers";
const formatUSD = (i: any) => utils.formatEther((i || 0).toString());

interface IProps {
  loans: ILoan[];
  onVote: (loanAddress: string, amount: string) => void;
  cornVoteBalance?: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) { return order; }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface IHeadCell {
  disablePadding: boolean;
  id: keyof ILoan;
  label: string;
  numeric: boolean;
}

const headCells: IHeadCell[] = [
  { id: "address", numeric: false, disablePadding: true, label: "Loan Address" },
  { id: "borrower", numeric: false, disablePadding: false, label: "Borrower Address" },
  { id: "type", numeric: false, disablePadding: false, label: "Type" },
  { id: "state", numeric: false, disablePadding: false, label: "State" },
  { id: "amount", numeric: true, disablePadding: false, label: "Amount (cUSD)" },
  { id: "duration", numeric: true, disablePadding: false, label: "Duration (blocks)" },
  {
    id: "votePercentage",
    numeric: true,
    disablePadding: false,
    label: "Voting Status"
  },
];

interface IEnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ILoan) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: IEnhancedTableProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" as Padding : "normal" as Padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props: any) => {
  const classes = useToolbarStyles();
  const { numSelected, selected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Loans
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
          { selected[0].state === "application" ? (
              <Tooltip title="Approve">
                <IconButton aria-label="Approve" onClick={props.onVote}>
                  <ThumbUp />
                </IconButton>
              </Tooltip>
          ) : null }
          <Tooltip title="View">
            <IconButton aria-label="View" onClick={props.onView}>
              <ViewIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.object,
  onVote: () => PropTypes.func,
  onView: () => PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const getIconForState = (state: ILoanState) => {
  if (state === "application") {
    return (<AssignmentIcon />);
  }

  if (state === "approved") {
    return (<AssignmentTurnedInIcon />);
  }

  if (state === "funded") {
    return (<MonetizationOnIcon />);
  }

  if (state === "started") {
    return (<CreditCardIcon />);
  }

  if (state === "finalized") {
    return (<CheckCircleOutlineIcon />);
  }
};

const LoanList: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles();
  const history = useHistory();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof ILoan>("address");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page/* , setPage */] = React.useState(0);
  const [rowsPerPage/* , setRowsPerPage */] = React.useState(5);

  const [votingDialogOpen, setVotingDialogOpen] = React.useState(false);
  const [votingDialogLoading, setVotingDialogLoading] = React.useState(false);

  const handleLoanVote = async (amount: string) => {
    setVotingDialogLoading(true);
    await props.onVote(selected[0], amount);
    setVotingDialogLoading(false);
  };

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = props.loans.map((n) => n.address as string);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: any, name: any) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  /* const handleChangePage = (event: any, newPage: number) => {
   *   setPage(newPage);
   * };

   * const handleChangeRowsPerPage = (event: any) => {
   *   setRowsPerPage(parseInt(event.target.value, 10));
   *   setPage(0);
   * }; */

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.loans.length - page * rowsPerPage);
  const getSelected = () => {
    return props.loans.reduce((m, l) => {
      if (selected.indexOf(l.address) !== -1) {
        m.push(l);
      }
      return m;
    }, [] as any);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={getSelected()}
          onView={() => history.push(`/loan/${selected[0]}`) }
          onVote={() => setVotingDialogOpen(true) }
        />
        {
          selected.length > 0 ? (
            <LoanVoting
              loan={selected}
              open={votingDialogOpen}
              cornVoteBalance={props.cornVoteBalance}
              votesRequired={getSelected()[0].votesRequired}
              onCancel={() => setVotingDialogOpen(false) }
              onSubmit={handleLoanVote}
              isLoading={votingDialogLoading}
            />) : null
        }
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.loans.length}
            />
            <TableBody>
              {stableSort(props.loans, getComparator(order, orderBy))
                .map((row: ILoan, index: any) => {
                  const isItemSelected = isSelected(row.address);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.address)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.address}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>

                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        <AddressLink addr={row.address} />
                      </TableCell>

                      <TableCell>
                        <AddressLink addr={row.borrower} />
                      </TableCell>

                      <TableCell>{row.type}</TableCell>

                      <TableCell>
                        <Chip variant="outlined" icon={getIconForState(row.state)} label={row.state} />
                      </TableCell>

                      <TableCell align="right">{formatUSD(row.amount)}</TableCell>
                      <TableCell align="right">{row.duration}</TableCell>
                      <TableCell align="right">
                        <LinearProgress
                          value={parseFloat(row.votePercentage)}
                          variant="determinate"
                        />
                        {row.votePercentage}%
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default LoanList;
