import React from "react";
import { useContractKit } from "@celo-tools/use-contractkit";
import { useBlockNumber } from "../helpers";
import useEthRPCStore from "../stores/useEthRPCStore";

import Loan from "@masa-finance/smart-contracts/build/contracts/Loan.json";

const loanAbi = Loan.abi;

const useLoans = (loanAddresses?: string[]) => {
  const { kit } = useContractKit();
  const [erpc] = useEthRPCStore();
  const [blockNumber] = useBlockNumber(erpc);

  const [loans, setLoans] = React.useState<any>([]);
  const [loanAddrs, setLoanAddrs] = React.useState<any>(loanAddresses || []);

  React.useEffect(() => {
    if (loanAddrs.length === 0) { return; }

    const proms = loanAddrs.map((loanAddr: any) => {
      const l = {
        address: loanAddr,
        contract: new kit.web3.eth.Contract(loanAbi as any, loanAddr),
        data: {},
      } as any;
      l.data.address = l.contract._address;

      const subProms = [] as any;

      const fields = {
        getBalance: "balance",
        getBalanceUsed: "balanceUsed",
        getInterestPaid: "interestPaid",
        getInterestOwed: "interestOwed",
        getPool: "pool",
        getAmount: "amount",
        borrower: "borrower",
        getRate: "rate",
        getDuration: "duration",
        getEndBlock: "end",
        getCornVotesAssigned: "end",
        getLastPaymentBlock: "lastPaymentBlock",
        getPaymentDueBlock: "nextPaymentBlock",
        getPaymentFrequency: "paymentFrequency",
        getType: "type",
        isApplication: "isApplication",
        isApproved: "isApproved",
        isFunded: "isFunded",
        isStarted: "isStarted",
        isFinalized: "isFinalized",
        getCornVotePercentage: "votePercentage",
        getVotePercentageThreshold: "voteThreshold",
        getVotesRequired: "votesRequired",
      } as any;

      Object.keys(fields).forEach((m) => {
        subProms.push(l.contract.methods[m]().call().then((r: any) => { l.data[fields[m]] = r; }));
      });

      return Promise.all(subProms).then(() => l);
    });

    Promise.all(proms).then((newLoans: any) => {
      setLoans(newLoans.map((l: any) => {
        const data = l.data;
        if (data.isApplication) {
          data.state = "application";
        } else if (data.isApproved) {
          data.state = "approved";
        } else if (data.isFunded) {
          data.state = "funded";
        } else if (data.isStarted) {
          data.state = "started";
        } else if (data.isFinalized) {
          data.state = "finalized";
        }

        l.data.votePercentage = Math.min((
          parseFloat(l.data.votePercentage) /
          parseFloat(l.data.voteThreshold)
        ) * 100, 100);
        return l;
      }));
    });

  }, [kit, loanAddrs, blockNumber]);

  return [loans, setLoanAddrs];
};

export default useLoans;
