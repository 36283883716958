import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Card, CardActions, CardContent } from "@material-ui/core";

interface IProps {
  onSubmit: () => void;
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Finalize: React.FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
         Finalize the loan
        </Typography>
        <Typography variant="h5" component="h2">
          The loan has reached maturity and may now be finalized.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => props.onSubmit()}>Finalize</Button>
      </CardActions>
    </Card>
  );
};

export default Finalize;
